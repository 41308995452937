<template>
  <div class="grid formgrid p-fluid">
        <div class="col-12">
          <Loader v-model="loading" />
        <div class="card">
            <Panel header="Almacen">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="['new','save','delete']" @new="newEntity" @save="saveEntity" @delete="deleteEntity" />
                    </div>
                    <FormInputText wrapperClass="field col-12 xl:col-4" label="Clave" v-model="entity.key_name" />
                    <FormInputText wrapperClass="field col-12 xl:col-8" label="Nombre" v-model="entity.name" />
                    <FormInputSwitch wrapperClass="field col-12 xl:col-2" label="Primario" v-model="entity.is_primary"  />
                    <FormInputSwitch :disabled="entity.is_primary" wrapperClass="field col-12 xl:col-2" label="Disponible para venta" v-model="entity.is_sale_available"  />
                </div>                   
            </Panel>
        </div>
        </div>
        <div class="col-12">
        <div class="card">
            <BasicDatatable :rows="entities" :selectionMode="'single'" :headers="headers" @selected="selectEntity"/>
        </div>
        </div>
  </div>
</template>
<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputSwitch from "../../../components/general/FormInputSwitch.vue";
import Loader from "../../../components/general/Loader.vue";
import formMixin from "../../../mixins/form";

import { warehouse } from "../../../models/warehouse";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { fillObject, HeaderGrid } from '../../../utilities/General';
export default {
  mixins: [formMixin],
  expose: ['refresh'],
  components: { Loader, FormInputText, FormInputSwitch, FormToolbar, BasicDatatable },
  data() {
    return {
      loading: false,
      entity: new warehouse(),
      entities: [],
      headers: [
        new HeaderGrid("Clave", "key_name"),
        new HeaderGrid("Nombre", "name")
      ]
    };
  },
  watch: {
    "entity.is_primary"(newValue) {
        if (newValue) {
            this.entity.is_sale_available = true;
        }
    }
  },
  methods: {
    async refresh() {
        this.loading = true;
        this.entity = new warehouse(this.session);
        try {
            this.entities = await this.entity.all();
        } catch (error) {
            this.showError(error);
        } finally {
            this.loading = false;
        }
    },
    deleteEntity(entity) { 
      console.log(entity);
    },
    selectEntity(entity) {
      this.entity = fillObject(this.entity, entity);
    },
    newEntity() {
      this.entity = new warehouse(this.session);
    },
    async saveEntity() {
      this.loading = true;
      try {
        var newEntity = await this.entity.save();
        this.entities.push(newEntity);
        this.newEntity();
        this.showSuccess("Informacion guardada con exito");
      } catch (error) {
        this.showError(error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>

<style>
</style>

