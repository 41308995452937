<template>
  <div class="grid formgrid p-fluid">
    <Helper v-model="productHelper" :headers="headersProduct" :rows="products" :header="'Buscar producto'" @selected="selectProduct" />
        <div class="col-12">
           <Loader v-model="loading" /> 
        <div class="card">
            <Panel header="Nuevo Movimiento">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="['new','save']" @new="newEntity" @save="saveEntity" />
                    </div>
                    <FormInputText :valid="validate.validations.id_pos_product" @enter="searchProduct" @search="productHelper.visible = true" :search="true" wrapperClass="field col-12 xl:col-4" label="Producto" v-model="entity.sku" />
                    <FormInputText wrapperClass="field col-12 xl:col-8" :readonly="true" label="Nombre" v-model="entity.name" />
                    <FormDropDown  :valid="validate.validations.id_pos_warehouse" wrapperClass="field col-12 xl:col-4" label="Almacen" v-model="entity.id_pos_warehouse" :options="warehouses" :optionLabel="'name'" :optionValue="'id'" />                    
                    <FormDropDown wrapperClass="field col-12 xl:col-4" label="Tipo Movimiento" v-model="entity.id_movement" :options="[{id: 1, text: 'ENTRADA'}, {id: 2, text: 'SALIDA'}]" :optionLabel="'text'" :optionValue="'id'" />                    
                    <FormDropDown :valid="validate.validations.id_movement_type" wrapperClass="field col-12 xl:col-4" label="Movimiento" v-model="entity.id_movement_type" :options="movementTypes" :optionLabel="'text'" :optionValue="'id'" />                    
                    <FormInputNumber :valid="validate.validations.quantity"  wrapperClass="field col-12 xl:col-4" label="Cantidad" v-model="entity.quantity" />
                    <FormInputNumber :valid="validate.validations.cost" :mode="'decimal'" :min="0.00" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-12 xl:col-4" label="Costo" v-model="entity.cost" />
                    <FormInputNumber :mode="'decimal'" :min="0.00" :minFractionDigits="2" :maxFractionDigits="2" wrapperClass="field col-12 xl:col-4" label="Total" :readonly="true" v-model="total" />
                </div>                   
            </Panel>
        </div>
        </div>
  </div>
</template>

<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormDropDown from "../../../components/general/FormDropdown.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import Loader from "../../../components/general/Loader.vue";

import Helper from "../../../components/general/HelperDialog.vue";
import { warehouse } from '../../../models/warehouse';
import { warehouse_movement } from '../../../models/warehouse_movement';
import { HeaderGrid, movementTypes, Rule, validate } from '../../../utilities/General';
import { product } from '../../../models/product';

import formMixin from "../../../mixins/form";

var math = require("mathjs");

export default {
    expose: ['refresh'],
    mixins: [formMixin],
    components: {Helper, Loader, FormInputText, FormToolbar, FormDropDown, FormInputNumber},
    data() {
        return {
            loading: false,
            entity: new warehouse_movement(),
            warehouses: [],
            inputs: [],
            outputs: [],
            headersProduct: [
                new HeaderGrid("SKU","key_name"),
                new HeaderGrid("Nombre","name"),
                new HeaderGrid("Costo","cost", { type: "decimal", editable: true }),
            ],
            products: [],
            productHelper: {
                visible: false,
            },
            validate: {
                valid: false,
                validations: {
                    id_pos_product: null,
                    id_pos_warehouse: null,
                    id_movement_type: null,
                    quantity: null,
                    cost: null
                }
            },
            rules: [
                new Rule({ name: "id_pos_product" }),
                new Rule({ name: "id_pos_warehouse"}),
                new Rule({ name: "id_movement_type"}),
                new Rule({ name: "quantity"}),
                new Rule({ name: "cost"})
            ],
        }
    },
    watch: {
        "entity.id_movement"() {
            this.entity.id_movement_type = null;
        }
    },
    computed: {
        movementTypes() {
            return this.entity.id_movement == 1 ? this.inputs 
                        : this.entity.id_movement == 2 ? this.outputs
                        : [];
        },
        total() {
            return math.chain(this.entity.quantity ?? 0).multiply(this.entity.cost ?? 0).done() ?? 0.00;
        }
    },
    methods: {
        searchProduct() {
            try {
                let product = this.products.find(x => x.key_name.toUpperCase() == this.entity.sku.toUpperCase());
                if (product == null) {
                    throw "No se encontro el producto con SKU " + this.entity.sku;
                }else {
                    this.selectProduct(product);
                }
            } catch (error) {
                this.showError(error);
            }
        },
        selectProduct(product) {
            this.entity.id_pos_product = product.id;
            this.entity.cost = product.cost;
            this.entity.name = product.key_name + " - " + product.name;
            this.entity.sku = product.key_name;
        },
        newEntity() { 
                this.entity = new warehouse_movement(this.session);
        },
        async saveEntity() {
            this.loading = true;
            try {
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid)
                    throw "Favor de verificar los campos"
                this.entity.name_movement_type = this.movementTypes.find(x => x.id == this.entity.id_movement_type).text;
                this.entity.is_input = this.entity.id_movement == 1;
                await this.entity.save();
                this.showSuccess("Registro guardado con exito");
                this.entity = new warehouse_movement(this.session);
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                this.warehouses = await new warehouse(this.session).all();
                let movements = await movementTypes();
                this.inputs = movements.entradas;
                this.outputs = movements.salidas;
                this.products = await new product(this.session).all();
            } catch (error) {
                this.showError(error);
            } finally { 
                this.loading = false;
            }
        }
    },
    mounted() {
        this.entity = new warehouse_movement(this.session);
        // this.refresh();
    }
}
</script>

<style>

</style>