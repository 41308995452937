<template>
  <div class="grid formgrid p-fluid">
        <div class="col-12">
        <Loader v-model="loading" /> 
        <div class="card">
            <Panel header="Kardex de Movimientos">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="['list']" @list="list" />
                    </div>
                    <FormCalendar wrapperClass="field col-12 xl:col-4" label="Fecha Inicio" v-model="entity.initial_date"></FormCalendar>
                    <FormCalendar wrapperClass="field col-12 xl:col-4" label="Fecha Fin" v-model="entity.final_date"></FormCalendar>
                    <FormDropDown wrapperClass="field col-12 xl:col-4" label="Almacen" v-model="entity.id_pos_warehouse" :options="warehouses" :optionLabel="'name'" :optionValue="'id'" />                    
                    <div class="col-12">
                        <BasicDatatable :selectedColumns="selectedColumns" :gridlines="true" :rows="rows" :headers="headers" />
                    </div>
                </div>                   
            </Panel>
        </div>
        </div>
  </div>
</template>

<script>

import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormDropDown from "../../../components/general/FormDropdown.vue";
import Loader from "../../../components/general/Loader.vue";

import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { warehouse } from '../../../models/warehouse';
import { HeaderGrid, Rule } from '../../../utilities/General';

import formMixin from "../../../mixins/form";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import { warehouse_movement } from '../../../models/warehouse_movement';
export default {
    props: {
        mode: {
            type: Number,
            default: 1
        }
    },
    expose: ['refresh'],
    mixins: [formMixin],
    components: {Loader, FormCalendar, FormToolbar, FormDropDown, BasicDatatable},
    data() {
        return {
            loading: false,
            entity: {
                id_pos_warehouse: null,
                initial_date: new Date(),
                final_date: new Date()
            },
            warehouses: [],
            rows: [],
            headers: [
                new HeaderGrid('Fecha','date', { type: 'date-time' }),
                new HeaderGrid('SKU','product_sku'),
                new HeaderGrid('Producto','product'),
                new HeaderGrid('Tipo','type'),
                new HeaderGrid('Movimiento','movement'),
                new HeaderGrid('Inicial','before_stock', { function: 'SUM'}),
                new HeaderGrid('Cantidad','quantity', { function: 'SUM'}),
                new HeaderGrid('Final','after_stock', { function: 'SUM'}),
                new HeaderGrid('Costo','cost'),
                new HeaderGrid('Importe','amount', { function: 'SUM'}),
            ],
            validate: {
                valid: false,
                validations: {
                    id: null
                }
            },
            rules: [
                new Rule({ name: "id" })
            ],
        }
    },
    mounted() {
        this.selectedColumns = this.headers.filter(x => x.name != 'Inicial' && x.name != 'Final');
    },
    methods: {
        async list() {
            this.loading = true;
            try {
                let body = {
                    ...this.entity
                };
                body.id_pos_warehouse = body.id_pos_warehouse.toString();
                this.rows = await new warehouse_movement(this.session).kardex(body);
            } catch (error) {
                this.showError(error);
            } finally { 
                this.loading = false;
            }
        },
        async refresh() {
            this.warehouses = await new warehouse(this.session).all();
            this.entity = {
                id_pos_warehouse: this.warehouses.find(x => x.is_primary).id,
                initial_date: new Date(),
                final_date: new Date()
            };
            await this.list();
        }
    }
}
</script>

<style>

</style>