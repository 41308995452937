import axios from "axios";

export class warehouse_movement
{
    constructor(session) {
        this.user = session ? session.user : null;
        this.id_company = session ? session.id_company : null;
        this.id_branch = session ? session.id_branch : null;
        this.token = session ? session.token : null;
        this.id = null;
        this.id_movement_type = null;
        this.name_movement_type = null;
        this.is_input = false;
        this.id_pos_product = null;
        this.quantity = 1;
        this.cost = 0.00;
        this.amount = 0.00;
        this.date = new Date();
        this.id_pos_warehouse = null;
        this.before_stock = 0.00;
        this.after_stock = 0.00;
    }

    async kardex(body) {
        let response = await axios.post("tpv/warehousing/kardex", body, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }

    async save() {
        let response = await axios.post("tpv/warehousing/movement", this, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
                token: this.token
            }
        });
        return response.data;
    }
}