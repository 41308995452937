<template>
  <div class="grid formgrid p-fluid">
        <div class="col-12">
        <Loader v-model="loading" /> 
        <div class="card">
            <Panel header="Nuevo Movimiento">
                <div class="formgrid grid p-fluid">
                    <div class="col-12">
                        <FormToolbar :actions="mode == 1 ? ['new','save','list'] : ['list']" @new="newEntity" @save="saveEntity" />
                    </div>
                    <FormDropDown  :valid="validate.validations.id_pos_warehouse" wrapperClass="field col-12 xl:col-12" label="Almacen" v-model="entity.id" :options="warehouses" :optionLabel="'name'" :optionValue="'id'" />                    
                    <div class="col-12">
                        <BasicDatatable :gridlines="true" :rows="stocks" :headers="headers" />
                    </div>
                </div>                   
            </Panel>
        </div>
        </div>
  </div>
</template>

<script>
import FormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormDropDown from "../../../components/general/FormDropdown.vue";
import Loader from "../../../components/general/Loader.vue";

import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import { warehouse } from '../../../models/warehouse';
import { HeaderGrid, Rule, validate } from '../../../utilities/General';

import formMixin from "../../../mixins/form";
export default {
    props: {
        mode: {
            type: Number,
            default: 1
        }
    },
    expose: ['refresh'],
    mixins: [formMixin],
    components: {Loader, FormToolbar, FormDropDown, BasicDatatable},
    data() {
        return {
            loading: false,
            entity: new warehouse(),
            warehouses: [],
            stocks: [],
            headers: [
                new HeaderGrid('SKU','product_key_name'),
                new HeaderGrid('Producto','product_name'),
                new HeaderGrid('Existencia Teorica','current_stock', {type: 'decimal'}),
                new HeaderGrid('Existencia Real','real_stock', {type: 'decimal', editable: true}),
            ],
            validate: {
                valid: false,
                validations: {
                    id: null
                }
            },
            rules: [
                new Rule({ name: "id" })
            ],
        }
    },
    methods: {
        newEntity() { 
            if (this.stock.length > 0) {
                this.stocks = this.stocks.map(x => {
                    return {
                        ...x,
                        real_stock: x.current_stock
                    }
                })  
            }      
        },
        async saveEntity() {
            this.loading = true;
            try {
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid)
                    throw "Favor de verificar los campos"
                await this.entity.adjust(this.stocks);
                this.showSuccess("Ajuste completado con exito");
                this.stocks.forEach(stock => {
                    stock.current_stock = stock.real_stock;
                });
            } catch (error) {
                this.showError(error);
            } finally {
                this.loading = false;
            }
        },
        async list() {
            this.loading = true;
            try {
                this.stocks = await this.entity.status();
                this.stocks = this.stocks.map(x => {
                    return {
                        ...x,
                        real_stock: x.current_stock
                    }
                })
            } catch (error) {
                this.showError(error);
            } finally { 
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                this.warehouses = await new warehouse(this.session).all();
                this.entity.id = this.warehouses.find(x => x.is_primary).id;
                //Tambien traemos la infor del status
                this.stocks = await this.entity.status();
                this.stocks = this.stocks.map(x => {
                    return {
                        ...x,
                        real_stock: x.current_stock
                    }
                })
            } catch (error) {
                this.showError(error);
            } finally { 
                this.loading = false;
            }
        }
    },
    mounted() {
        this.entity = new warehouse(this.session);
        if (this.mode == 2) {
            this.headers = this.headers.filter(x => x.value != "real_stock");
            this.headers.find(x => x.value == "current_stock").name = "Existencia";
        }
        // this.refresh();
    }
}
</script>

<style>

</style>