<template>
  <div class="grid formgrid p-fluid">
    <div class="col-12">
      <div class="card">
        <Loader v-model="loading" />
        <Panel header="Inventario">
          <TabView @tab-change="onTabChange" :activeIndex="tab">
            <TabPanel header="Estatus Actual">
              <AjusteUI :mode="2" ref="estatus_actual"/>
            </TabPanel>
            <TabPanel header="Almacenes">
                <AlmacenUI ref="almacen" />
            </TabPanel>
            <TabPanel header="Registrar Movimiento">
                <MovimientosUI ref="movimientos" />
            </TabPanel>
            <TabPanel header="Ajustar Almacen">
                <AjusteUI ref="ajuste"/>
            </TabPanel>
            <TabPanel header="Kardex">
                <KardexUI ref="kardex"/>
            </TabPanel>
        </TabView>
        </Panel>
      </div>
    </div>
  </div>
</template>
<script>
import AlmacenUI from "./Inventario/Almacen.vue";
import MovimientosUI from "./Inventario/Movimientos.vue";
import AjusteUI from "./Inventario/AjusteAlmacen.vue";
import KardexUI from "./Inventario/Kardex.vue";

import Loader from "../../components/general/Loader.vue";
export default {
  components: { KardexUI, AjusteUI, AlmacenUI, MovimientosUI, Loader },
  data() {
    return {
        tab:0,
        loading: false
    };
  },
  methods: {
    onTabChange(payload) {
      switch (payload.index) {
        case 0:
          this.$refs.estatus_actual.refresh();
          break;
        case 1:
          this.$refs.almacen.refresh();
          break;
        case 2:
          this.$refs.movimientos.refresh();
          break;
        case 3:
          this.$refs.ajuste.refresh();
          break;
        case 4:
          this.$refs.kardex.refresh();
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.$refs.estatus_actual.refresh();
  }
};
</script>

<style>
</style>

